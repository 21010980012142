var LinkButton = {
  enableElementsIn: function(id, text) {
    $j(`${id} input[type=submit]`).each(LinkButton.disablementFunc(false, text));
  },

  disableElementsIn: function(id, text) {
    $j(`${id} input[type=submit]`).each(LinkButton.disablementFunc(true, text));
  },

  disablementFunc: function(disabled, label) {
    return function() {
      this.disabled = disabled;
      this.value = label;
    };
  }
};

var DeleteButton = {
  enable: function(button) {
    $j(button).removeAttr('disabled');
    $j(button).html('Delete');
  },
  disable: function(button) {
    $j(button).attr('disabled', 'disabled');
    $j(button).html('Deleting...');
  }
}

var SubmitButton = {
  disable: function(idx, button) {
    button.value = 'Saving...';
  },
  enable: function(idx, button) {
    button.value = 'Save';
  }
};

export { LinkButton, DeleteButton, SubmitButton }